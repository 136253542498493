import { useMemo } from 'react';
import useSWR from 'swr';
import { CurrencyType, InitialData, RewardData } from '@/src/app/api/types';
import { API_ENDPOINTS } from '@/src/app/constants/api';
import { AnimationFlask } from '../../AnimationFlask/AnimationFlask';
import { AnimationOrbits } from '../../AnimationOrbit/AnimationOrbit';
import { AnimationPuddle } from '../../AnimationPuddle/AnimationPuddle';
import { testnetChainIds } from '@/src/app/constants/blockchain';
import { formatNumber } from '@/src/app/utils/formatNumber';
import { animationFlaskVariants, animationOrbitVariants, animationPuddleVariants } from '../constant';

export const useMetricsData = (initialData: InitialData) => {
  // const { data: yelData } = useSWR<PriceData>(API_ENDPOINTS.YEL_PRICE, {
  //   fallbackData: initialData.yelPrice
  // });

  const { data: rewardsData } = useSWR<RewardData[]>(API_ENDPOINTS.REWARDS, {
    fallbackData: initialData.rewards
  });

  const countChains = useMemo(
    () =>
     initialData?.aprs ? Array.from(new Set(initialData?.aprs.map((item) => item?.chainId))).filter(
        (item) => item && !testnetChainIds.includes(item)
      )?.length : null,
    [initialData?.aprs]
  );

  const deployedVaults: number | null = useMemo(
    () =>
      Array.isArray(initialData?.assets)
        ? initialData?.assets?.filter(
            (asset) => asset.type === CurrencyType.POTION && !testnetChainIds.includes(asset.chainId)
          )?.length
        : null,
    [initialData?.assets]
  );

  const yelRewards: string | null = useMemo(() => {
    if (rewardsData?.length === 0) {
      return null;
    }
    const reducedRewards: number | null =
      rewardsData?.reduce(
        (acc, { rewards, chain }) => acc + (rewards && !testnetChainIds.includes(chain) ? rewards : 0),
        0
      ) ?? null;
    return typeof reducedRewards === 'number' ? formatNumber(reducedRewards) : null;
  }, [rewardsData]);

  const metricsData = [
    { animation: <AnimationOrbits />, title: 'Chains connected', value: countChains, variants: animationOrbitVariants },
    {
      animation: <AnimationFlask />,
      title: 'Deployed Vaults',
      value: deployedVaults,
      variants: animationFlaskVariants
    },
    {
      animation: <AnimationPuddle />,
      title: 'Total earned',
      value: yelRewards,
      variants: animationPuddleVariants
    }
  ];

  return { metricsData };
};
