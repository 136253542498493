const app = process.env.NEXT_PUBLIC_APP_ORIGIN_URL ?? 'https://app.yel.finance';

export const Links = {
  // Internal
  APP: app,
  POTIONS: `${app}/potions`,
  BRIDGE: `${app}/bridge`,
  // External
  TELEGRAM: 'https://t.me/yelfinance',
  DISCORD: 'https://discord.gg/5HRAprgaGV',
  TWITTER: 'https://x.com/yel_finance',
  MEDIUM: 'https://yel-finance.medium.com',
  DOCS: 'https://yield-enhancement-labs.gitbook.io/yield-enhancement-labs'
};
