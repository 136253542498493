'use client';
import { formatNumber } from '@/src/app/utils/formatNumber';
import { useVaultsData } from './hooks/useVaultsData';
import { InitialData } from '@/src/app/api/types';
import styles from './VaultList.module.scss';
import Image from 'next/image';
import { blockcainIcons } from '@/src/app/constants/blockchain';
import { SkeletonWrapper } from '@/src/app/components/SkeletonWrapper/SkeletonWrapper';
import Link from 'next/link';
import { Links } from '@/src/app/constants/links';

export const VaultList = ({ initialData }: { initialData: InitialData }) => {
  const { filteredAprs } = useVaultsData(initialData);
  return (
    <div className={styles.list}>
      {/* @ts-expect-error type mismatch */}
      {filteredAprs?.map(({ icon, symbol, title, holderapr, color, chainId, address }, index) => (
        <Link href={`${Links.POTIONS}/${address}?mode=pro`} key={index} className={styles.item} style={{ background: color }}>
          <div className={styles.currencyContainer}>
            <SkeletonWrapper isLoading={!icon} className={styles.icon} isCircle width={90} height={90}>
              <Image loading='lazy' alt='currency icon' width={90} height={90} src={icon} className={styles.icon} />
            </SkeletonWrapper>
            {chainId && <div className={styles.blockchainIconWrapper}>{blockcainIcons[chainId]}</div>}
            <SkeletonWrapper isLoading={!symbol} width={90} height={30}>
              <div className={styles.currency}>{symbol}</div>
            </SkeletonWrapper>
          </div>
          <div className={styles.infoWrapper}>
            <div className={styles.title}>{title}</div>
            <SkeletonWrapper isLoading={!holderapr && holderapr !== 0} width={65} height={20}>
              <div className={styles.value}>{`${formatNumber(holderapr, 2)}%`}</div>
            </SkeletonWrapper>
          </div>
        </Link>
      ))}
    </div>
  );
};
